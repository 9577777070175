<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('History Income')"
    ok-title="Accept"
    size="xl"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col md="3">
            <b-card
              v-if="detailData"
              no-body
              class="mb-1"
              border-variant="primary"
              header-bg-variant="transparent"
            >
              <div class="m-2">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="justify-content-center">
                    <div class="text-center mb-1">
                      <b-avatar
                        v-if="detailData.image"
                        class="text-center"
                        rounded="sm"
                        square
                        size="10rem"
                        :src="detailData.image"
                      />
                      <b-avatar
                        v-else
                        class="text-center"
                        rounded="sm"
                        square
                        size="10rem"
                        :src="require('@/assets/images/blank/no_image.png')"
                      />
                    </div>
                    <div class="mb-1 text-center">
                      {{ detailData.firstName }} {{ detailData.lastName }}
                    </div>

                    <div class="mb-1 text-center">
                      {{ $t("Customer ID") }} : {{ detailData.userId.username }}
                    </div>
                  </div>
                </div>
                <b-row class="mb-1">
                  <b-col>
                    <div
                      class="d-flex align-items-center justify-content-around"
                    >
                      <b-avatar square :variant="`light-primary`" size="45">
                        <feather-icon size="21" :icon="'ShoppingCartIcon'" />
                      </b-avatar>
                      <div class="">
                        <p class="mb-0 text-bold">${{ 184 }}</p>
                        <small class="">{{ $t("Order") }}</small>
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="d-flex justify-content-around">
                      <b-avatar square :variant="`light-primary`" size="45">
                        <feather-icon size="21" :icon="'DollarSignIcon'" />
                      </b-avatar>
                      <div class="">
                        <p class="mb-0 text-bold">${{ 8456 }}</p>
                        <small class="">{{ $t("All bonus") }}</small>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-button block variant="primary" class="mb-1">
                  <span>
                    {{ selectLevel(detailData.userId.memberLevelId) }}
                  </span>

                  <feather-icon size="14" :icon="'SendIcon'" />
                </b-button>
              </div>
            </b-card>
            <b-card
              v-if="detailData"
              no-body
              class="mb-1"
              border-variant="primary"
              header-bg-variant="transparent"
            >
              <b-list-group>
                <b-list-group-item active>{{
                  $t("Purchase bonus")
                }}</b-list-group-item>
                <b-list-group-item>{{ $t("Invite bonus") }}</b-list-group-item>
                <b-list-group-item>{{ $t("Team bonus") }}</b-list-group-item>
                <b-list-group-item>{{ $t("Sponsor bonus") }}</b-list-group-item>
                <b-list-group-item>{{
                  $t("Matching bonus")
                }}</b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <b-col md="9" class="mb-1">
            <b-card
              class="mb-0"
              border-variant="primary"
              header-bg-variant="transparent"
            >
              <b-row>
                <b-col>
                  <label>{{ $t("Show") }}</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector v-select-custom"
                    @input="pageSize"
                  />
                  <label for="">{{ $t("entries") }}</label>
                  <span class="ml-1 text-muted"
                    >{{ currentPage * perPage - (perPage - 1) }} -
                    {{
                      queriedItems - currentPage * perPage > 0
                        ? currentPage * perPage
                        : queriedItems
                    }}
                    {{ $t("of") }} {{ queriedItems }}</span
                  >
                  <v-select
                    v-model="statusCredits"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionStatusCredit"
                    :reduce="(OptionStatusCredit) => OptionStatusCredit.value"
                    :clearable="false"
                    class="per-page-selector v-select-custom"
                  />
                </b-col>

                <b-col cols="12" md="6" class="b-col-custom">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-group
                      :label="$t('Start Date')"
                      label-for="start-date"
                      class="d-inline-block mr-75"
                    >
                      <flat-pickr
                        id="startDate"
                        v-model="startDate"
                        class="form-control"
                        :config="configs"
                        @input="onChangeDate()"
                      />
                    </b-form-group>
                    <b-form-group
                      :label="$t('End Date')"
                      label-for="end-date"
                      class="mr-75"
                    >
                      <flat-pickr
                        id="endDate"
                        v-model="endDate"
                        class="form-control"
                        :config="configs"
                        @input="onChangeDate()"
                      />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <StatisticCardHorizontalReverse
                    color="primary"
                    icon="DollarSignIcon"
                    :statistic="10"
                    :statistic-title="$t('All purchase bonuses')"
                  />
                </b-col>
              </b-row>

              <b-table
                ref="refUserListTable"
                striped
                responsive
                class="position-relative"
                :items="respData"
                :fields="tableColumns"
                primary-key="_id"
                show-empty
                :empty-text="$t('No matching records found')"
                style="height: 60vh;"
              >
                <template #cell(name)="data">
                  <p class="align-text-bottom line-height-1">
                    {{ data.item.userInfoId.firstName }}
                    {{ data.item.userInfoId.firstName }}
                  </p>
                </template>
                <template #cell(statusCredits)="data">
                  <b-badge
                    pill
                    :variant="colorStatusCredit(data.item.statusCredits)"
                  >
                    {{ data.item.statusCredits | firstStringUpper }}
                  </b-badge>
                </template>
                <template #cell(author)="data">
                  <p class="align-text-bottom line-height-1">
                    {{
                      data.item.author !== null && data.item.author
                        ? $t("Admin")
                        : $t("Member")
                    }}
                  </p>
                </template>
                <template #cell(created)="data">
                  <p class="align-text-bottom line-height-1">
                    {{ data.item.created | formatDateTimeNoSec }}
                  </p>
                </template>
                <!-- Column: Action -->
                <template #cell(action)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="sidebarEdit(data.item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteData(data.item._id)">
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted"
                      >{{ currentPage * perPage - (perPage - 1) }} -
                      {{
                        queriedItems - currentPage * perPage > 0
                          ? currentPage * perPage
                          : queriedItems
                      }}
                      {{ $t("of") }} {{ queriedItems }}</span
                    >
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="queriedItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @input="nextPage"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <template #modal-footer>
      <div class="w-100" />
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import StatisticCardHorizontalReverse from "@core/components/statistics-cards/StatisticCardHorizontalReverse.vue";

import {
  BModal,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BCard,
  BTable,
  BPagination,
  BBadge,
  BAvatar,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import perPageOptions from "@/perPageOptions";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { Thai } from "flatpickr/dist/l10n/th.js";
import moment from "moment";
import store from "@/store";
import storeModuleMemberLevel from "@/store/services/eCommerce/memberLevel";

const STORE_MODULE_NAME_MEMBER_LEVEL = "memberLevel";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    Cleave,
    BFormGroup,
    BCard,
    BTable,
    BPagination,
    BBadge,
    BAvatar,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    StatisticCardHorizontalReverse,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: "isModalActive",
    event: "update:is-modal-active",
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      currentPage: 1,
      searchQuery: "",
      perPage: localStorage.getItem("itemsPerPage")
        ? localStorage.getItem("itemsPerPage")
        : 50,
      type: "",
      statusCredits: "all",
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      startDate: moment().format("DD-MM-YYYY"),
      endDate: moment().format("DD-MM-YYYY"),
      configs: {
        enableTime: false,
        dateFormat: "d-m-Y",
        locale: Thai,
      },
      required,
      perPageOptions,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: "amount", label: this.$t("Amount"), sortable: false },
        { key: "name", label: this.$t("Name"), sortable: false },
        {
          key: "statusCredits",
          label: this.$t("Status Credit"),
          sortable: false,
        },
        { key: "author", label: this.$t("Action By"), sortable: false },
        { key: "created", label: this.$t("Created"), sortable: false },
        // { key: 'action', label: this.$t('ACTION'), sortable: false },
      ];
    },
    OptionStatusCredit() {
      return [
        { name: this.$t("All"), value: "all" },
        { name: this.$t("Get credits"), value: "get_credits" },
        { name: this.$t("Use credits"), value: "use_credits" },
      ];
    },
    OptionStatusPayment() {
      return [
        { name: this.$t("All"), value: "all" },
        { name: this.$t("Create"), value: "create" },
        { name: this.$t("Pending"), value: "pending" },
        { name: this.$t("Success"), value: "success" },
        { name: this.$t("Cancel"), value: "cancel" },
        { name: this.$t("Delete"), value: "delete" },
        { name: this.$t("Error"), value: "error" },
      ];
    },
    OptionMethod() {
      return [
        { name: this.$t("All"), value: "all" },
        { name: this.$t("Income"), value: "income" },
        { name: this.$t("Expenses"), value: "expenses" },
        { name: this.$t("Deposit"), value: "deposit" },
        { name: this.$t("Withdraw"), value: "withdraw" },
        { name: this.$t("Refund"), value: "refund" },
        { name: this.$t("Bonus"), value: "bonus" },
        { name: this.$t("Pay"), value: "pay" },
        { name: this.$t("Error"), value: "error" },
        { name: this.$t("Cancel"), value: "cancel" },
      ];
    },
    respData() {
      return store.state[this.storeModuleName].respDataHistory != null
        ? store.state[this.storeModuleName].respDataHistory.data
        : [];
    },
    detailData() {
      return store.state[this.storeModuleName].respDataHistory != null
        ? store.state[this.storeModuleName].respDataHistory.detail
        : null;
    },
    queriedItems() {
      return store.state[this.storeModuleName].respDataHistory != null
        ? store.state[this.storeModuleName].respDataHistory.max
        : 0;
    },
    respDataMemberLevel() {
      return store.state[STORE_MODULE_NAME_MEMBER_LEVEL].respData != null
        ? store.state[STORE_MODULE_NAME_MEMBER_LEVEL].respData.data.map(
            (e) => ({
              key: e._id,
              label: `${this.showFromCurrentLanguage(e.name)}`,
              sortable: false,
            })
          )
        : [];
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        this.getMemberLevel();
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset();
          this.initValues();
        } else {
          const { _id } = this.data;
          this.dataId = _id;
          this.get();
        }
      }
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL))
      store.registerModule(
        STORE_MODULE_NAME_MEMBER_LEVEL,
        storeModuleMemberLevel
      );
  },
  setup() {
    return { STORE_MODULE_NAME_MEMBER_LEVEL };
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex((e) => e.lang === this.$i18n.locale);
      if (indexLang > -1) {
        return data[indexLang].value;
      }
      return "";
    },
    selectLevel(id) {
      if (id !== null) {
        const index = this.respDataMemberLevel.findIndex((e) => e.key === id);
        if (index > -1) {
          return this.respDataMemberLevel[index].label;
        }
        return this.$t("No matching records found");
      }
      return this.$t("Didn't choose");
    },
    initValues() {
      this.dataId = null;
    },
    onChangeDate() {
      console.log(this.startDate);
      console.log(this.endDate);
      this.get();
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        type: this.type,
        statusCredits: this.statusCredits,
        statusPayment: this.statusPayment,
        method: this.method,
        userInfoId: this.dataId,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      // this.show = true
      store
        .dispatch(`${this.storeModuleName}/getHistory`, obj)
        .then((result) => {
          // this.show = false
          console.log("fetch Success : ", result);
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch((error) => {
          // this.show = false
          console.log("fetchUsers Error : ", error);
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    getMemberLevel() {
      const obj = {
        currentPage: 0,
        pageSize: 0,
        searchQuery: "",
      };
      store
        .dispatch(`${STORE_MODULE_NAME_MEMBER_LEVEL}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch((error) => {
          console.log("fetchUsers Error : ", error);
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    pageSize(size) {
      localStorage.setItem("itemsPerPage", size);
      this.perPage = size;
      this.currentPage = 1;
      this.get();
    },
    nextPage(page) {
      this.currentPage = page;
      this.get();
    },
    colorStatusCredit(value) {
      if (value === "get_credits") {
        return "success";
      }
      if (value === "use_credits") {
        return "warning";
      }
      if (value === "cancel_credits") {
        return "danger";
      }
      return "dark";
    },
    // firstStringUpper(value) {
    //   const text = value.substr(0, 1)
    //   const word = value.slice(1).replace('_', ' ')
    //   return this.$t(text.toUpperCase() + word)
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
